import * as React from "react"
import Layout from '../components/Layout'
import {css} from '@emotion/react'
import AmenitiesComponent from '../components/amenities'

const AmenitiesPage = () => {
  return(
     <div>
      <Layout title="adarsh developers |  Amenities">
      <div style={{marginTop:"12vh", textAlign:"center"}}>
          <AmenitiesComponent/>
        </div>
      </Layout>
     </div>
  )
}

export default AmenitiesPage

