import React from "react";
import { css } from "@emotion/react";
import { CgGym } from "react-icons/cg";
import {
  GiBasketballBasket,
  GiTennisRacket,
  GiMeditation,
  GiVikingLonghouse,
  GiKidSlide,
  GiParkBench,
  GiVikingChurch,
  GiPineTree,
} from "react-icons/gi";
import { RiRunFill } from "react-icons/ri";
import { ImPower } from "react-icons/im";
import { FaSkating } from "react-icons/fa";
import Container from "./Container";
import TitleComponent from "./TitleComponent";

const Amenities = () => {
  const List = [
    {
      icon: <CgGym />,
      Name: "Gym",
    },
    {
      icon: <GiBasketballBasket />,
      Name: "Basketball Cort",
    },
    {
      icon: <GiTennisRacket />,
      Name: "Tennis Cort",
    },
    {
      icon: <GiMeditation />,
      Name: "Meditation",
    },
    {
      icon: <GiVikingLonghouse />,
      Name: "Club House",
    },
    {
      icon: <GiKidSlide />,
      Name: "Kids Playing Area",
    },
    {
      icon: <GiParkBench />,
      Name: "Walking Park",
    },
    {
      icon: <GiVikingChurch />,
      Name: "Indoor games",
    },
    {
      icon: <RiRunFill />,
      Name: "Joggging Tracks",
    },
    {
      icon: <FaSkating />,
      Name: "Skating Area",
    },
    {
      icon: <ImPower />,
      Name: "24 hrs Power",
    },
    {
      icon: <GiPineTree />,
      Name: "Landscape Garden",
    },
  ];

  const displayingList = () => {
    return List.map((item, i) => {
      return (
        <div key={i}>
          {item.icon}
          <br />
          <p>{item.Name}</p>
        </div>
      );
    });
  };
  return (
    <Container>
      <TitleComponent title="Amenities" size="3vw" color="#7a8c57" />
      <div css={main}>{displayingList()}</div>
    </Container>
  );
};

export default Amenities;

const main = css`
  display: grid;
  grid-template-columns: 2fr 2fr 2fr 2fr 2fr 2fr;
  text-align: center;
  grid-gap: 30px;
  padding: 65px;
  @media (max-width: 600px) {
    grid-template-columns: 2fr 2fr;
    padding: 15px;
    grid-gap: 15px;
  }
  svg {
    font-size: 50px;
  }
  div {
    box-shadow: 1px 1px 25px #e0e0e0;
    padding: 1.9vw 0.8vw 0.8vw;
    height: 8vw;
    @media (max-width: 600px) {
      padding: 20px;
      height: 150px;
    }
    p {
      font-weight: 600;
      font-size: 1.1vw;
      @media (max-width: 600px) {
        font-size: 16px;
        padding-top: 20px;
      }
    }
    :hover {
      cursor: pointer;
      border: 1px solid #388036bd;
    }
  }
`;

const three = css``;
